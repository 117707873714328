import * as React from 'react';
import { type PublicClient, usePublicClient } from 'wagmi';
import { providers } from 'ethers';
import { type HttpTransport } from 'viem';

export function publicClientToProvider(publicClient: PublicClient) {
    const { chain, transport } = publicClient;
    const network = {
        chainId: chain.id,
        name: chain.name,
        ensAddress: chain.contracts?.ensRegistry?.address,
    };

    if ((window as any).web3 && (window as any).web3.currentProvider) {
        return new providers.Web3Provider((window as any).web3.currentProvider);
    }
    if (transport.type === 'fallback') {
        return new providers.FallbackProvider(
            (transport.transports as ReturnType<HttpTransport>[]).map(
                ({ value }) =>
                    new providers.JsonRpcProvider(value?.url, network),
            ),
        );
    }
    return new providers.JsonRpcProvider(transport.url, network);
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
    const publicClient = usePublicClient({ chainId });
    return React.useMemo(
        () => publicClientToProvider(publicClient),
        [publicClient],
    );
}
