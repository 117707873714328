import { ChainId } from "../constant";
import { makeAutoObservable } from "mobx";
import storage from "utils/storage";
export class User {
  isOpenConnectModal: boolean = false;
  isDark: boolean = !!storage.get("lazai-theme") || true;
  web3: any;
  address: string = "";
  chainId: ChainId = ChainId.testnet;
  connected: boolean = false;
  constructor() {
    makeAutoObservable<this>(this);
  }

  toggleConnectModal = () => {
    this.isOpenConnectModal = !this.isOpenConnectModal;
  };

  toggleTheme = () => {
    this.isDark = !this.isDark;
  };

  setWeb3 = (web3: any) => {
    this.web3 = web3;
  };

  setAddress = (address: string) => {
    this.address = address;
  };

  setConnected = (connected: boolean) => {
    this.connected = connected;
  };
  setChainId = (chainId: ChainId) => {
    this.chainId = chainId;
  };

  clearLoginInfo = () => {
    this.web3 = undefined;
    this.chainId = ChainId.testnet;
    this.address = "";
    this.connected = false;
  };
}

export default new User();
