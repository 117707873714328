import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import Config from './config';
import { ChainId } from 'constant';

const web3ChainId = Number(
    ((window as any)?.web3 as any)?.currentProvider.chainId,
);

const chainId =
    [ChainId.mainnet, ChainId.testnet].indexOf(web3ChainId) >= 0
        ? web3ChainId
        : ChainId.mainnet;

console.log('currentChainId', chainId);

function getCurrentChainId(): ChainId {
    const web3ChainId = Number(
        ((window as any)?.web3 as any)?.currentProvider.chainId,
    );

    return [ChainId.mainnet, ChainId.testnet].indexOf(web3ChainId) >= 0
        ? web3ChainId
        : ChainId.mainnet;
}

function getApolloClient(key: string) {
    return () =>
        new ApolloClient({
            cache: new InMemoryCache(),
            link: new HttpLink({
                uri: (Config[getCurrentChainId()] as any)[key],
            }),
        });
}

const client = getApolloClient('graph');
export const ExchangeSubgraph = getApolloClient('exchangeSubgraph');

export default client;
