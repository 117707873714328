import { useMemo } from 'react';
import user from 'stores/user';
import { useEthersProvider } from './useEthersProvider';
import { getContract } from 'utils';
import { Contract } from 'ethers';
import ERC20ABI from 'contracts/erc20.json';

export function useContract(
    address: string | undefined,
    ABI: any,
    withSignerIfPossible = true,
): Contract | null {
    const { address: account } = user;
    const provider = useEthersProvider();

    return useMemo(() => {
        if (!address || !ABI || !provider) return null;
        try {
            return getContract(
                address,
                ABI,
                provider as any,
                withSignerIfPossible && account ? account : undefined,
            );
        } catch (error) {
            console.error('Failed to get contract', error);
            return null;
        }
    }, [address, ABI, provider, withSignerIfPossible, account]);
}

export function useGetContractByChainId() {
    const { address: account } = user;
    const provider = useEthersProvider();
    return function (address: string, ABI: any) {
        return getContract(address, ABI, provider as any, account);
    };
}

