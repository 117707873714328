const storage = {
    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get(key: string) {
        const res = localStorage.getItem(key);
        return res ? JSON.parse(res) : undefined;
    },
    remove(key: string) {
        localStorage.removeItem(key);
    },
    clear() {
        localStorage.clear();
    },
};

export default storage;
