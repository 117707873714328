import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import SelectLng from "../SelectLng";
import { NavLink, useHistory, useLocation } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import ConnectWalletButton from "./connectWallet";
import SelectNetwork from "components/SelectNetwork";
import styled, { createGlobalStyle } from "styled-components";
import user from "stores/user";
import { observer } from "mobx-react";
import { TYPE } from "theme";
import TooltipInfo from "components/TooltipInfo";
import application from "stores/application";
import { Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ChainId } from "constant";
import { logo } from "common-helpers/img";
const HeaderWapper = styled.div`
  position: fixed;
  z-index: 88;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: transparent;

  // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  /* background: ${({ theme }) => theme.c.bg01}; */

  &.showBg {
    background: #01204c;

    transition: all 0.3s linear;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    img {
      width: 100px;
    }

    .logoText {
      font-size: 18px;
      color: ${({ theme }) => theme.c.t01};
      font-weight: 800;
      margin-left: 20px;
    }
    .valentinesday {
      margin-left: 16px;
      max-width: 70px;
      max-height: 56px;
    }
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 48px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0
        `}
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    height: 100%;

    .right-bg {
      display: none;
    }

    .nav-container {
      flex: 1;
      height: 100%;
      .nav-list {
        display: flex;
        align-items: center;
        // margin: 0;
        height: 100%;
        list-style: none;
        margin: 0;

        .nav-item {
          margin: 0 10px;

          font-size: 18px;
          color: #fff;
          border-radius: 100px;
          cursor: pointer;

          &.profile {
            display: none;
          }

          a {
            display: inline-block;
            padding: 10px 16px;
            color: #fff;
            text-decoration: none;
            font-size: 18px;
            font-weight: bold;

            &:hover {
              color: $pink;
            }

            &.active {
              border-bottom: 1px solid #fff;
            }
          }
        }
      }
    }

    .connect-button {
      margin-bottom: -25px;
      padding: 10px 20px;
      background-color: #214652;
      font-size: 0.8rem;
      color: #fff;
      border: 0;
      border-radius: 100px;
      cursor: pointer;

      &.wrong {
        background-color: #ff6871;
      }
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;

        .content {
            width: 100%;

            .menu-icon {
                display: block;
                position: relative;
                width: 24px;
                height: 24px;
                font-size: 0;
                z-index: 2;

                &::before,
                &::after {
                    display: block;
                    position: absolute;
                    left: 2px;
                    content: '';
                    width: 20px;
                    height: 2px;
                    background: $pink;
                    transition: 0.3s;
                    cursor: pointer;
                }

                &::before {
                    top: 6.5px;
                }

                &::after {
                    top: 14.5px;
                }
            }

            .right {
                display: none;
                flex-direction: column;
                justify-content: center;
                position: fixed;
                z-index: -1;
                top: 0;
                right: 0;
                width: 80vw;
                height: 100vh;
                font-size: 0;
                opacity: 0;
                transition: 0.3s;
                border-left: 4px solid $pink;

                .right-bg {
                    display: block;
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: transparent;
                    z-index: -1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $lightDark;
                    opacity: 0.8;
                    z-index: -1;
                }

                .nav-container {
                    flex: initial;
                    width: 100%;
                    height: initial;

                    .nav-list {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-left: 0;
                        margin-top: -100px;
                        width: 100%;

                        .nav-item {
                            margin-bottom: 20px;
                            padding: 0;
                            width: 100%;
                            text-align: center;
                            font-size: 1.6rem;
                            background-color: transparent;

                            &.profile {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.open {
            .menu-icon {
                &::before,
                &::after {
                    top: 10px;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }

            .right {
                display: flex;
                opacity: 1;
                z-index: 1;
            }
        }
    `}
`;

const MobileWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
        position: fixed;
        z-index: 10;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${theme.c.bg01};
        box-sizing: border-box;
        padding: 8px 4px;
  `}
`;

const ThemeButton = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 20px;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.c.bg02};
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    `}
`;

const ActWebbanner = styled.img`
  position: absolute;
  width: 400px;
  height: 56px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 400px;
    height: 56px;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `}
`;
const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  flex-direction: row;
  margin-right: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 200px;
    flex-direction: column;
    padding: 20px 0;
    `};
`;
const Nav = styled(NavLink)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #0e0905;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s linear;
  &.active {
    div {
      font-weight: 600;
    }
    .line {
      display: block;
      position: absolute;
      bottom: -20px;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background: #fff;
    }
  }

  .line {
    display: none;
  }
`;

const OutterLink = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #0e0905;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s linear;
`;

const NetworkLabel = styled.div`
  padding: 4px 10px;
  background-color: #f9eeee;
  color: #000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Menu = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    `};
`;

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background: #01204c;
  }
`;

const GlobalStyle = createGlobalStyle`
    .MuiDrawer-paper{
        background: linear-gradient(180deg, #fffdf6 0%, #fff6d4 100%);

    }
`;

interface Props {
  showConnectButton?: boolean;
  connected?: boolean;
  onClickConnect?(): void;
  onClickDisconnect?(): void;
  address?: string;
  showLanguage?: boolean;
  notSupportedChain?: boolean;
}

function Header(props: Props) {
  const {
    // connected,
    // onClickConnect,
    showLanguage,
    // notSupportedChain,
  } = props;
  const [headerBgShow, setHeaderBgShow] = useState(false);
  const { connected, address: walletAddress } = user;
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const dom: Element | null = document.querySelector(".container");
    const scroll = () => {
      if (dom) {
        if (dom.scrollTop >= 40) {
          setHeaderBgShow(true);
        } else {
          setHeaderBgShow(false);
        }
      }
    };
    if (dom) {
      dom.addEventListener("scroll", scroll);
    }

    return () => {
      if (dom) {
        dom.removeEventListener("scroll", scroll);
      }
    };
  }, []);

  const isMint = useMemo(() => {
    return new RegExp("/mint&stake").test(pathname);
  }, [pathname]);

  const Navs = () => (
    <NavWrapper>
      <GlobalStyle />
      <Nav
        id="home"
        to="/home"
        className={(isActive) => (isActive ? "active" : "")}
      >
        <TYPE.main>About</TYPE.main>
        <div className="line"></div>
      </Nav>
      {/* <OutterLink
        href="https://drive.google.com/file/d/13VlfmhuZlZwQrK95aBTqsfXQVqCr59TP/view"
        target="_blank"
      >
        <TYPE.main>iDAO</TYPE.main>
        <div className="line"></div>
      </OutterLink> */}

      {/* <OutterLink
        href="https://drive.google.com/file/d/13VlfmhuZlZwQrK95aBTqsfXQVqCr59TP/view"
        target="_blank"
      >
        <TYPE.main>Litepaper</TYPE.main>
        <div className="line"></div>
      </OutterLink> */}
      {/* <OutterLink
        onClick={() => {
          window.open("xxx");
        }}
      >
        <TYPE.main>Contact</TYPE.main>
      </OutterLink> */}
      {/* <Nav
            to="/dashboard/overview"
            className={(isActive) =>
                isActive ? 'active' : ''
            }
        >
            <TYPE.main>Dashboard</TYPE.main>
            <div className="line"></div>
        </Nav> */}
    </NavWrapper>
  );
  return (
    <>
      <HeaderWapper
        className={classNames(
          "header",
          isOpen && "open",
          headerBgShow && "showBg"
        )}
      >
        <div className="content">
          <div className="logoContainer">
            <img
              className="logo"
              src={logo}
              alt="lazai logo"
              onClick={() => {
                history.push("/home");
              }}
            />
            {/* <div className="logoText">ENKI</div> */}
          </div>
          <Menu
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <MenuIcon style={{ color: "#fff" }} />
          </Menu>
          <div className="right" onClick={() => setIsOpen(false)}>
            <Navs />
            {!application.hideMainnet && <SelectNetwork />}
            {false && (
              <ConnectWalletButton
                connected={connected}
                walletAddress={walletAddress || ""}
              />
            )}
            {/* <ThemeButton onClick={user.toggleTheme}>
                            <ThemeImg />
                        </ThemeButton> */}
            {showLanguage && <SelectLng />}
          </div>
        </div>
      </HeaderWapper>
      <MobileWrapper>
        {false && (
          <>
            <ConnectWalletButton
              connected={connected}
              walletAddress={walletAddress || ""}
            />
            <SelectNetwork />
          </>
        )}
        <StyledDrawer
          anchor="right"
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Navs />
        </StyledDrawer>
        {/* <ThemeButton onClick={user.toggleTheme}>
                    <ThemeImg />
                </ThemeButton> */}
      </MobileWrapper>
    </>
  );
}

export default observer(Header);
