import { ChainId, NETWORK_CONFIG, Token } from 'constant';
import React from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import { Link, TYPE } from '../../theme';
import { AlertTriangle, ArrowUpCircle } from 'react-feather';
import Button from '../Button';
import { AutoColumn, ColumnCenter } from '../Column';
import { useAddToken } from 'hooks/useAddToken';
import { observer } from 'mobx-react-lite';
import user from 'stores/user';
import application from 'stores/application';
import { metamaskLogo } from 'common-helpers/img';

const Wrapper = styled.div`
    width: 100%;
`;
const Section = styled(AutoColumn)`
    padding: 24px;
`;

const BottomSection = styled(Section)`
    background-color: #ccc;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`;

const ConfirmedIcon = styled(ColumnCenter)`
    padding: 30px 0;
`;

const AddToken = styled.div`
    border-radius: 12px;
    padding: 14px;
    box-sizing: border-box;
    background: #fff;
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;
    .metamaskLogo {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
`;

function ConfirmationPendingContent({
    onDismiss,
    pendingText,
}: {
    onDismiss: () => void;
    pendingText: string;
}) {
    return (
        <Wrapper>
            <Section>
                <AutoColumn gap="12px" justify={'center'}>
                    <TYPE.main fontWeight={500} fontSize={20}>
                        Waiting For Confirmation
                    </TYPE.main>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.main
                            fontWeight={600}
                            fontSize={14}
                            color=""
                            textAlign="center"
                        >
                            {pendingText}
                        </TYPE.main>
                    </AutoColumn>
                    <TYPE.main fontSize={12} color="#565A69" textAlign="center">
                        Confirm this transaction in your wallet
                    </TYPE.main>
                </AutoColumn>
            </Section>
        </Wrapper>
    );
}

function TransactionSubmittedContent({
    onDismiss,
    chainId,
    hash,
    addTokenToMetamask,
}: {
    onDismiss: () => void;
    hash: string | undefined;
    chainId: ChainId;
    addTokenToMetamask?: Token;
}) {
    const [canAdd, addToMetaMask] = useAddToken(addTokenToMetamask);

    return (
        <Wrapper>
            <Section>
                <ConfirmedIcon>
                    <ArrowUpCircle strokeWidth={0.5} size={90} />
                </ConfirmedIcon>
                <AutoColumn gap="12px" justify={'center'}>
                    <TYPE.main fontWeight={500} fontSize={20}>
                        Transaction Submitted
                    </TYPE.main>
                    {chainId && hash && (
                        <Link
                            href={`${NETWORK_CONFIG[chainId].explore}/transaction`}
                        >
                            <TYPE.main
                                fontWeight={500}
                                fontSize={14}
                                color="t05"
                            >
                                View on {NETWORK_CONFIG[chainId].scanLable}
                            </TYPE.main>
                        </Link>
                    )}
                    {addTokenToMetamask && canAdd && (
                        <AddToken
                            onClick={() => {
                                addToMetaMask();
                            }}
                        >
                            <img
                                className="metamaskLogo"
                                src={metamaskLogo}
                                alt="netswap"
                            />{' '}
                            Add {addTokenToMetamask.symbol} to Metamask
                        </AddToken>
                    )}
                    <Button
                        onClick={onDismiss}
                        style={{ margin: '20px 0 0 0' }}
                    >
                        Close
                    </Button>
                </AutoColumn>
            </Section>
        </Wrapper>
    );
}

export function TransactionErrorContent({
    message,
    onDismiss,
}: {
    message: string;
    onDismiss: () => void;
}) {
    return (
        <Wrapper>
            <Section>
                <AutoColumn
                    style={{ marginTop: 20, padding: '2rem 0' }}
                    gap="24px"
                    justify="center"
                >
                    <AlertTriangle
                        color="red"
                        style={{ strokeWidth: 1.5 }}
                        size={64}
                    />
                    <TYPE.main
                        fontWeight={500}
                        fontSize={16}
                        color="red"
                        style={{ textAlign: 'center', width: '85%' }}
                    >
                        {message}
                    </TYPE.main>
                </AutoColumn>
            </Section>
            <BottomSection gap="12px">
                <Button onClick={onDismiss}>Dismiss</Button>
            </BottomSection>
        </Wrapper>
    );
}

// interface ConfirmationModalProps {
//     isOpen: boolean;
//     onDismiss: () => void;
//     hash: string | undefined;
//     content: () => React.ReactNode;
//     attemptingTxn: boolean;
//     pendingText: string;
//     addTokenToMetamask?: Token;
// }

// function TransactionConfirmationModal({
//     isOpen,
//     onDismiss,
//     attemptingTxn,
//     hash,
//     pendingText,
//     content,
//     addTokenToMetamask,
// }: ConfirmationModalProps) {
//     const { chainId } = user;

//     if (!chainId) return null;

//     // confirmation screen
//     return (
//         <Modal isOpen={isOpen} onClose={onDismiss}>
//             {attemptingTxn ? (
//                 <ConfirmationPendingContent
//                     onDismiss={onDismiss}
//                     pendingText={pendingText}
//                 />
//             ) : hash ? (
//                 <TransactionSubmittedContent
//                     chainId={chainId}
//                     hash={hash}
//                     onDismiss={onDismiss}
//                     addTokenToMetamask={addTokenToMetamask}
//                 />
//             ) : (
//                 content()
//             )}
//         </Modal>
//     );
// }

function TransactionConfirmationModalWithErrorMessage() {
    const { chainId } = user;
    const {
        confirmModal: {
            isOpen,
            attemptingTxn,
            hash,
            pendingText,
            errorMessage,
            addTokenToMetamask,
        },
    } = application;

    function onDismiss() {
        application.setCloseConfirModalState();
    }

    if (!chainId) return null;

    // confirmation screen
    return (
        <Modal isOpen={isOpen} onClose={onDismiss}>
            {attemptingTxn ? (
                <ConfirmationPendingContent
                    onDismiss={onDismiss}
                    pendingText={pendingText}
                />
            ) : hash ? (
                <TransactionSubmittedContent
                    chainId={chainId}
                    hash={hash}
                    onDismiss={onDismiss}
                    addTokenToMetamask={addTokenToMetamask}
                />
            ) : (
                <TransactionErrorContent
                    onDismiss={onDismiss}
                    message={
                        errorMessage ||
                        'Something wrong, please try again later'
                    }
                />
            )}
        </Modal>
    );
}

export default observer(TransactionConfirmationModalWithErrorMessage);
