import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "components/Header";
import Homepage from "pages/homepage";
// import Forbiden403 from 'pages/403';
import ConnectWalletModal from "components/ConnectWallet";
import Connecting from "components/Loading/ConnectingLoading";
import styled from "styled-components";
import UseWalletConnectHook from "hooks/useWalletconnect";
import { observer } from "mobx-react";
import user from "stores/user";
import { useConnect } from "wagmi";
import TransactionConfirmationModal from "components/TransactionConfirmationModal";

import Footer from "components/Footer";
const Container = styled.div`
  width: 100vw;
  // box-sizing: border-box;
  // padding-top: 93px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background: url('./assets/images/bg.png') no-repeat; */
  background-size: 100% 100%;
`;

const MainView = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: white;
  padding-top: 120px;
  background: #01204c;
  /* padding-bottom: 130px; */
  z-index: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     padding-top: 120px;
    `}
`;

function App() {
  const { i18n } = useTranslation();
  const { isLoading } = useConnect();
  const { isOpenConnectModal } = user;
  const location = useLocation();
  // const history = useHistory();

  useEffect(() => {
    if (i18n.language === "ru") {
      document.getElementsByTagName("html")[0].className = "ru";
    }
  }, [i18n]);

  const isIframeLink = location && location.pathname === "/banxa";
  const is403 = location && location.pathname === "/403";

  return (
    <>
      {/* <UseWalletConnectHook /> */}
      <TransactionConfirmationModal />
      <Container className={"container"}>
        {!is403 && <Header />}
        <MainView className="mainView">
          <Switch>
            <Route path="/home">
              <Homepage />
            </Route>
            {/* <Route path="/403">
                <Forbiden403 />
            </Route> */}

            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </MainView>
        <Footer />
      </Container>
      <ConnectWalletModal
        isOpen={isOpenConnectModal}
        onClose={() => {
          user.toggleConnectModal();
        }}
      />

      <Connecting loading={isLoading} />
    </>
  );
}

export default observer(App);
