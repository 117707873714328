import React, { useCallback, useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
// import style from './index.module.scss';
import cx from "classnames";
import styled from "styled-components";

const StyledButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;

  border-radius: 12px;

  &.middle {
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    height: 38px;
    width: auto;
  }

  &.normal {
    padding: 15px 24px;
    font-size: 18px;
    height: 64px;
    border-radius: 32px;
    box-sizing: border-box;
    font-weight: bold;

    ${({ theme }) => theme.mediaWidth.upToSmall`
        height 48px;
        font-size: 24px;
        padding: 10px 20px;
        `};
  }

  &.primary {
    background: linear-gradient(270deg, #2353f2 0%, #06d2fd 100%);
    box-shadow: 0px 10px 10px 0px rgba(219, 154, 50, 0.1);
    color: #fff;
  }

  &.transparent {
    background: transparent;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
    }
  }

  &.outline {
    background: transparent;

    border: 1px solid #e8dbd8;
    color: #fff;
  }

  &.disabled {
    background: linear-gradient(270deg, #2353f2 0%, #06d2fd 100%);
    opacity: 0.6;
    border-image: none;
    box-shadow: none;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      border-image: none;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &.loading {
    color: transparent !important;
  }

  .loadingIcon {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // margin-top: -50%;
    // margin-left: -50%;
    color: #000;
  }
`;
interface Props {
  size?: "middle" | "normal";
  type?: "primary" | "transparent" | "outline";
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  showLoading?: boolean;
  loadingProps?: {
    classname?: string;
    size?: number;
  };
  style?: React.CSSProperties;
  onClick?:
    | ((event?: React.SyntheticEvent) => void)
    | ((event?: React.SyntheticEvent) => Promise<any>);
}

export default function Button(props: Props) {
  const {
    size = "normal",
    type = "primary",
    disabled,
    children,
    className,
    onClick,
    showLoading = false,
    loadingProps = {
      classname: "",
      size: 24,
    },
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  const mountedRef = useRef(true);

  loadingRef.current = loading;

  const handleLoading = useCallback(
    (value: any) => {
      if (mountedRef.current) {
        setLoading(value);
      }
    },
    [setLoading]
  );

  const handleClick = useCallback(
    (event: any) => {
      if (loadingRef.current || disabled || !onClick) {
        return;
      }

      const promise = onClick(event);
      if (
        promise != null &&
        typeof (promise as Promise<any>).then === "function"
      ) {
        handleLoading(true);
        (promise as Promise<any>)
          .then((result: any) => {
            handleLoading(false);
            return result;
          })
          .catch((e) => {
            handleLoading(false);
          });
      }
    },
    [loadingRef, mountedRef, disabled, handleLoading, onClick]
  );

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const classes = [
    size,
    type,
    disabled && "disabled",
    loading && showLoading && "loading",
    className,
  ];

  return (
    <StyledButton className={classes.join(" ")} onClick={handleClick} {...rest}>
      {children}
      {showLoading && loading && (
        <CircularProgress
          size={loadingProps.size}
          className={cx("loadingIcon", loadingProps.classname)}
        />
      )}
    </StyledButton>
  );
}
