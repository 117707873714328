import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import user from 'stores/user';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { theme } from 'theme';
function ThemeProvider({ children }: { children: React.ReactNode }) {
    const darkMode = user.isDark;

    const themeObject = useMemo(() => theme(darkMode), [darkMode]);

    return (
        <StyledComponentsThemeProvider theme={themeObject}>
            {children}
        </StyledComponentsThemeProvider>
    );
}

export default observer(ThemeProvider);
