/* eslint-disable no-script-url */
import React, { useState } from 'react';
import { formatHashValue } from 'utils';

import AccountDetail from 'components/AccountDetail';
import styled from 'styled-components';
import makeBlockie from 'ethereum-blockies-base64';
import { TYPE } from 'theme';
import useTokenBalance from 'hooks/useTokenBalance';
import ConnectWalletButton from 'pages/components/ConnectWalletButton';

const AccountButton = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px !important;
    padding: 0 0 0 10px !important;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.c.bg02};
    color: #9393aa;

    border-radius: 15px;
    overflow: hidden;
`;

const Address = styled.div`
    background: #0a0a15;
    height: 100%;
    display: flex;
    align-content: center;
    color: #fff;
    padding: 10px;

    border-radius: 15px;
    margin-left: 10px;

    img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        border-radius: 12px;
    }
`;

interface Props {
    connected: boolean;
    walletAddress: string;
}

export default function ConnectButton({ connected, walletAddress }: Props) {
    const [isOpenAccountDetail, setIsOpenAccountDetail] = useState(false);

    return (
        <>
            {!connected || !walletAddress ? (
                <ConnectWalletButton />
            ) : (
                <>
                    <AccountButton
                        onClick={() => {
                            setIsOpenAccountDetail(true);
                        }}
                    >
                        <TYPE.main fontSize={12}>
                            xxx Metis
                        </TYPE.main>
                        <Address>
                            <TYPE.main
                                color={'#fff'}
                                fontSize={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {formatHashValue(walletAddress)}
                            </TYPE.main>
                            <img src={makeBlockie(walletAddress)} alt="" />
                        </Address>
                        {/* <ArrowDropDownIcon className={style.hideDropdownIcon} /> */}
                    </AccountButton>
                </>
            )}
            <AccountDetail
                isOpen={isOpenAccountDetail}
                onClose={() => setIsOpenAccountDetail(false)}
            />
        </>
    );
}
