// eslint-disable-next-line no-use-before-define
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import client from './gql';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemedGlobalStyle } from 'theme';
import ThemeProvider from 'theme/ThemeProvider';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
} from '@web3modal/ethereum';

import { metis, metisGoerli } from 'wagmi/chains';
import { Web3Modal } from '@web3modal/react';
const projectId = 'c876a65ec55eccc4ce57e11335034b3d';

const chains = [metisGoerli];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ chains, projectId }),
    publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <ApolloProvider client={client as any}>
        <Suspense fallback={<></>}>
            <WagmiConfig config={wagmiConfig}>
                <StyledEngineProvider injectFirst>
                    <BrowserRouter>
                        <ThemeProvider>
                            <ThemedGlobalStyle />
                            <App />
                        </ThemeProvider>
                        <Web3Modal
                            projectId={projectId}
                            ethereumClient={ethereumClient}
                        />
                    </BrowserRouter>
                </StyledEngineProvider>
            </WagmiConfig>
        </Suspense>
    </ApolloProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
