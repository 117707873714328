import React, { ReactNode, CSSProperties, ReactElement, useState } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
interface Props {
    text?: string | ReactElement;
    style?: CSSProperties;
    placement?: 'top' | 'bottom' | 'top-start' | 'left';
    children?: ReactNode;
}

const QuestionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    border: none;
    background: none;
    outline: none;
    cursor: default;
    border-radius: 36px;
    color: #9d9d9d;
    :hover,
    :focus {
        opacity: 0.7;
    }

    svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        color: #ccc;
    }
`;

const TooltipsWrapper = styled(Tooltip)``;

export default function TooltipInfo({
    text = '',
    style: cssStyle,
    placement = 'top',
    children,
}: Props) {
    const [open, setOpen] = useState<boolean>(false);

    const show = () => {
        setOpen(true);
    };

    const hide = () => {
        setOpen(false);
    };

    return (
        <TooltipsWrapper
            title={text}
            placement={placement}
            arrow
            onMouseEnter={() => show()}
            onClick={() => show()}
            onMouseLeave={() => hide()}
            open={open || false}
        >
            {children ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        ...cssStyle,
                    }}
                >
                    {children}
                </div>
            ) : (
                <QuestionWrapper
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        ...cssStyle,
                    }}
                >
                    <HelpIcon />
                </QuestionWrapper>
            )}
        </TooltipsWrapper>
    );
}
