import Row, { RowBetween } from "components/Row";
import TooltipInfo from "components/TooltipInfo";
import React, { useMemo } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { TYPE } from "theme";
import Button from "components/Button";

import BannerBg from "assets/images/home/bg_top.png";
import { NavLink, useHistory } from "react-router-dom";
import { logo } from "common-helpers/img";
import { AutoColumn } from "components/Column";

const GlobalStyle = createGlobalStyle`
    .mainView {
        padding-bottom: 0;
    }
`;

const Wrapper = styled.div``;

const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 624px;
  background: url(${BannerBg}) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  margin-top: -120px;
  gap: 60px;
  .logo {
    width: 180px;
  }

  button {
    letter-spacing: 4px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    padding-top: 20px;
    background-size: cover;
    height: 500px;
    `};
`;

const Block = styled.div`
  width: 100%;
  background: #01204c;
  position: relative;
  z-index: 1;

  &.bg2 {
    background: #1c1f73;
  }

  .handsBg {
    position: absolute;
    right: 0;
    top: 0;
    width: 868px;
    height: 1044px;
    z-index: -1;
  }

  .ball {
    position: absolute;
    left: 0;
    top: 900px;
    z-index: -1;
    width: 445px;
    height: 559px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 222px;
    height: 280px;
    `};
  }

  .ballBg {
    position: absolute;
    left: 0;
    top: 600px;
    width: 868px;
    height: 1044px;
    z-index: -1;
    transform: rotateZ(180deg);
  }

  .thought {
    padding: 0;
    margin-top: -60px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
    width: 100%;
    `};
  }

  .whyBgTop {
    position: absolute;
    width: 549px;
    height: 327px;
    z-index: -1;
    top: 151px;
    left: 0;
  }

  .whyBgBottom {
    position: absolute;
    width: 559;
    height: 327px;
    z-index: -1;
    bottom: 0;
    right: 0;
  }

  .bg5 {
    position: absolute;
    right: 0;
    top: 0;
    width: 470px;
    height: 596px;
    z-index: -1;
  }

  .bg6 {
    position: absolute;
    left: 0;
    top: 20px;
    width: 284px;
    height: 334px;
    z-index: -1;
  }

  .bg8 {
    position: absolute;
    right: 0;
    top: 200px;
    width: 872px;
    height: 1023px;
    z-index: -1;
  }
`;

const InnerBlock = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding: 112px 0;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 30px 10px;
  `};

  &.showcase {
    padding: 20px;
  }

  &.dao {
    margin-top: 300px;
  }

  &.flex-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 42px;
  }

  .textWrapper1 {
    width: 70%;
    gap: 20px;
    position: relative;
    z-index: 1;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
  }

  .textWrapper2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 700px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
  }

  .about {
    background: #ffffff;
    border-radius: 40px;
    border: 2px solid #2353f2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 28px;
    gap: 47px;
    height: 80px;
  }

  .hands {
    position: absolute;
    right: 0;
    top: 20px;
    width: 472px;
    height: 792px;
    z-index: 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 118px;
    height: 198px;
    top: 100px;
    `};
  }

  .yourAIBg {
    position: absolute;
    width: 462px;
    height: 260px;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .bowl {
    position: absolute;
    bottom: -94px;
    left: 0;
    width: 176px;
    height: 152px;
    z-index: -2;
    transform: translateY(100%);
  }

  .showcase {
    width: 100%;
  }
`;

const LazAIIntroWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 23px;
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  `};
`;

const LazAIItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 360px;
  background: linear-gradient(
    315deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  border-radius: 32px;
  padding: 28px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `};
`;

const LazAI = styled.div`
  width: 121px;
  height: 36px;
  background: url(${require("assets/images/logo.png")});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 80px;
  height: 27px;
  `};
`;

const WhyWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 30px;
  flex-direction: row;
  width: 100%;
  margin-top: 100px;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  gap: 60px;
  align-items: center;
  `};
`;

const WhyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 0 12px 30px 12px;
  background: linear-gradient(180deg, #01204c 0%, #1c1f73 100%);
  border-radius: 24px;
  width: 290px;
  border: 1px solid #1c63f2;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  gap: 20px;
  `};
  img {
    width: 164px;
    height: 164px;
    margin-top: -82px;
    border-radius: 82px;
    overflow: hidden;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 82px;
    height: 82px;
    margin-top: -41px;
    `};
  }
`;

const VsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 100px;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  align-items: center;
  gap: 48px;
  `};
`;

const VsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  width: 290px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 24px;
  `};
`;

const CaseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  margin-top: 20px;
`;

const Case = styled.div`
  width: 370px;
  height: 120px;
  flex-shrink: 0;
  background: linear-gradient(
    315deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 24px;

  .icon {
    width: 48px;
    height: 48px;
  }

  .iconToken {
    width: 48px;
    height: 41px;
  }

  .check {
    width: 32px;
    height: 22px;
  }
`;

const ShowsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  gap: 108px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  gap: 60px;
  margin-top: 50px;
  `};
`;

const ShowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 160px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 60px;
  `};
`;

const ShowItem = styled.div`
  position: relative;
  width: 525px;
  height: 372px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 90vw;
  height: auto;
  `};

  img {
    width: 100%;
  }

  .button {
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 400px;
    height: 88px;
    background: linear-gradient(270deg, #2353f2 0%, #06d2fd 100%);
    border-radius: 44px;
    font-size: 1.6rem;
    font-family: Poppins, Poppins;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 250px;
    height: 60px;
    font-size: 18px;
    `};
  }
`;

export default function Home() {
  const history = useHistory();

  return (
    <Wrapper className="container">
      <GlobalStyle />
      <BannerWrapper>
        <img src={logo} className="logo" />
        {/* <Button
          onClick={() => {
            history.push("/");
          }}
        >
          GET STARTED
        </Button> */}
        <TYPE.main
          fontSize={32}
          mobileFz={24}
          letterSpacing={4}
          fontWeight={600}
          textAlign={"center"}
        >
          Unlock the Power of Decentralized AI
        </TYPE.main>
      </BannerWrapper>
      <Block>
        <InnerBlock>
          <AutoColumn className="textWrapper1">
            <TYPE.main fontSize={48} mobileFz={24} fontWeight={600}>
              Your AI Partner & Web3 Asset
            </TYPE.main>
            <TYPE.main fontSize={28} mobileFz={20} fontWeight={600}>
              LazAI transforms AI into your Web3 companion
            </TYPE.main>
            <TYPE.main fontSize={16} mobileFz={16}>
              ● Through personalized customization and our steadfast commitment
              to you
            </TYPE.main>
            <TYPE.main fontSize={16} mobileFz={16}>
              ● we guide you through decentralized innovation
            </TYPE.main>
            <TYPE.main fontSize={16} mobileFz={16}>
              ● securing ownership of AI assets
            </TYPE.main>
            <img
              src={require("assets/images/home/bowl.png")}
              alt=""
              className="bowl"
            />
          </AutoColumn>

          <img
            src={require("assets/images/home/hands.png")}
            className="hands"
            alt=""
          />

          <img
            src={require("assets/images/home/bg_txt.png")}
            className="yourAIBg"
            alt=""
          />
        </InnerBlock>
        <InnerBlock className="flex-end dao">
          <div className="textWrapper2">
            <TYPE.main
              fontSize={48}
              mobileFz={28}
              textAlign="right"
              flexShrink={1}
              color="#06D2FD"
              fontWeight={600}
            >
              LazAI DAO
            </TYPE.main>
            <TYPE.main fontSize={20} mobileFz={18} textAlign="right">
              LazAI DAO is where our Protocol & AI Bots unite under the iDAO
              concept, creating a decentralized, community-driven AI economy.
            </TYPE.main>
          </div>
          <div
            className="about"
            onClick={() => {
              window.open(
                "https://drive.google.com/file/d/13VlfmhuZlZwQrK95aBTqsfXQVqCr59TP/view"
              );
            }}
          >
            <TYPE.main
              fontSize={20}
              mobileFz={16}
              color={"#215AF3"}
              fontWeight={600}
            >
              iDAO Inspiration
            </TYPE.main>
            <img src={require("assets/images/home/ic_next.png")} alt="" />
          </div>
          <LazAIIntroWrapper>
            <LazAIItem>
              <TYPE.main
                fontSize={20}
                fontWeight={800}
                color={"#06D2FD"}
                mobileFz={18}
              >
                LazAI Protocol
              </TYPE.main>
              <TYPE.main fontSize={16} mobileFz={16}>
                Central to LazAI DAO, our Protocol secures your AI innovations
                as Full NFTs, ensuring your ownership and creative freedom
              </TYPE.main>
            </LazAIItem>
            <LazAIItem>
              <TYPE.main
                fontSize={20}
                fontWeight={800}
                color={"#06D2FD"}
                mobileFz={18}
              >
                LazAI BOT
              </TYPE.main>
              <TYPE.main fontSize={16} mobileFz={16}>
                LazAI Bots are your LazAI DAO sidekicks, offering custom Web3
                port to enhance creativity, efficiency, and engagenment.
              </TYPE.main>
            </LazAIItem>
          </LazAIIntroWrapper>
        </InnerBlock>
        <InnerBlock className="thought">
          <img
            src={require("assets/images/home/thought.png")}
            alt=""
            className="thought"
          />
        </InnerBlock>
        <img
          src={require("assets/images/home/hands_bg.png")}
          className="handsBg"
          alt=""
        />
        <img
          src={require("assets/images/home/hands_bg.png")}
          className="ballBg"
          alt=""
        />
        <img
          src={require("assets/images/home/ball.png")}
          alt=""
          className="ball"
        />
      </Block>
      <Block className="bg2">
        <InnerBlock>
          <Row justify="center" gap="18px">
            <TYPE.main fontSize={48} fontWeight={600} mobileFz={32}>
              Why
            </TYPE.main>
            <LazAI />
            <TYPE.main fontSize={48} fontWeight={600} mobileFz={32}>
              ?
            </TYPE.main>
          </Row>
          <WhyWrapper>
            {/* <WhyItem>
              <img src={require("assets/images/home/why/1.png")} alt="" />
              <TYPE.main textAlign={"center"} fontSize={20} mobileFz={18}>
                Tailored AI Companions
              </TYPE.main>
              <TYPE.desc fontSize={16} mobileFz={16} textAlign={"center"}>
                Central to LazAI DAO, our Protocol secures your AI innovations
                as Full NFTs, ensuring your ownership and creative freedom
              </TYPE.desc>
            </WhyItem> */}
            <WhyItem>
              <img src={require("assets/images/home/why/2.png")} alt="" />
              <TYPE.main textAlign={"center"} fontSize={20} mobileFz={18}>
                Protective Protocol
              </TYPE.main>
              <TYPE.desc fontSize={16} mobileFz={16} textAlign={"center"}>
                The LazAI Protocol convers AI models, datasets, and outcomes
                into Full NFTs
              </TYPE.desc>
            </WhyItem>
            <WhyItem>
              <img src={require("assets/images/home/why/3.png")} alt="" />
              <TYPE.main textAlign={"center"} fontSize={20} mobileFz={18}>
                Full NFT Control
              </TYPE.main>
              <TYPE.desc fontSize={16} mobileFz={16} textAlign={"center"}>
                Your AI creations are yours alone, secured as Full NFTs
              </TYPE.desc>
            </WhyItem>
            <WhyItem>
              <img src={require("assets/images/home/why/4.png")} alt="" />
              <TYPE.main textAlign={"center"} fontSize={20} mobileFz={18}>
                Collective Growth
              </TYPE.main>
              <TYPE.desc fontSize={16} mobileFz={16} textAlign={"center"}>
                Engage in a community where AI where AI collaboration equals
                shared prosperity
              </TYPE.desc>
            </WhyItem>
          </WhyWrapper>
        </InnerBlock>

        <img
          src={require("assets/images/home/why/bg_top.png")}
          alt=""
          className="whyBgTop"
        />
        <img
          src={require("assets/images/home/why/bg_bottom.png")}
          alt=""
          className="whyBgBottom"
        />
      </Block>
      <Block>
        <InnerBlock>
          <Row justify="center" gap="18px">
            <LazAI />
            <TYPE.main fontSize={48} fontWeight={600} mobileFz={36}>
              vs OTHER AI’S
            </TYPE.main>
          </Row>
          <Row justify="center">
            <TYPE.main
              fontSize={26}
              mobileFz={20}
              textAlign="center"
              marginTop={40}
              wordBreak
            >
              LazAI‘s open-source model training invites community <br />
              contribution, unlike other AI closed systems
            </TYPE.main>
          </Row>
          <VsWrapper>
            <VsItem>
              <TYPE.main textAlign={"center"} fontSize={20} mobileFz={16}>
                DIVERSE MODELS
              </TYPE.main>
              <TYPE.desc
                fontSize={16}
                mobileFz={16}
                fontWeight={14}
                textAlign={"center"}
              >
                LazAI’s open-source model traning invites community
                contribution, unlike other AI colsed systems.
              </TYPE.desc>
            </VsItem>
            <VsItem>
              <TYPE.main textAlign={"center"} fontSize={20} mobileFz={16}>
                PERSONALIZED EXPERIENCE
              </TYPE.main>
              <TYPE.desc
                fontSize={16}
                mobileFz={16}
                fontWeight={14}
                textAlign={"center"}
              >
                Custom AI models align wieh your unique narrative, surpassing
                other AI standard solutions.
              </TYPE.desc>
            </VsItem>
            <VsItem>
              <TYPE.main textAlign={"center"} fontSize={20} mobileFz={16}>
                COMMUNITY BOTS
              </TYPE.main>
              <TYPE.desc
                fontSize={16}
                mobileFz={16}
                fontWeight={14}
                textAlign={"center"}
              >
                LazAI BOTs serve as DAO members, fostering sustainable community
                interactions, in contrast to other AI productivity tools.
              </TYPE.desc>
            </VsItem>
          </VsWrapper>
        </InnerBlock>
        <InnerBlock className="showcase">
          <img
            src={require("assets/images/home/showcase.png")}
            alt=""
            className="showcase"
          />

          <CaseWrapper>
            <Case>
              <Row gap="16px">
                <img
                  src={require("assets/images/home/case/3.png")}
                  alt=""
                  className="icon"
                />
                <TYPE.main fontSize={22} mobileFz={16}>
                  Divers Models
                </TYPE.main>
              </Row>
              <img
                src={require("assets/images/home/ic_yes.png")}
                alt=""
                className="check"
              />
            </Case>
            <Case>
              <Row gap="16px">
                <img
                  src={require("assets/images/home/case/4.png")}
                  alt=""
                  className="icon"
                />
                <TYPE.main fontSize={22} mobileFz={16}>
                  Customizable Styles
                </TYPE.main>
              </Row>
              <img
                src={require("assets/images/home/ic_yes.png")}
                alt=""
                className="check"
              />
            </Case>
            <Case>
              <Row gap="16px">
                <img
                  src={require("assets/images/home/case/5.png")}
                  alt=""
                  className="icon"
                />
                <TYPE.main fontSize={22} mobileFz={16}>
                  Affordable Access
                </TYPE.main>
              </Row>
              <img
                src={require("assets/images/home/ic_yes.png")}
                alt=""
                className="check"
              />
            </Case>
            <Case>
              <Row gap="16px">
                <img
                  src={require("assets/images/home/case/2.png")}
                  alt=""
                  className="icon"
                />
                <TYPE.main fontSize={22} mobileFz={16}>
                  NFT Minting
                </TYPE.main>
              </Row>
              <img
                src={require("assets/images/home/ic_yes.png")}
                alt=""
                className="check"
              />
            </Case>
            <Case>
              <Row gap="16px">
                <img
                  src={require("assets/images/home/case/1.png")}
                  alt=""
                  className="iconToken"
                />
                <TYPE.main fontSize={22} mobileFz={16}>
                  Tokenomics
                </TYPE.main>
              </Row>
              <img
                src={require("assets/images/home/ic_yes.png")}
                alt=""
                className="check"
              />
            </Case>
          </CaseWrapper>
        </InnerBlock>
        <img
          src={require("assets/images/home/bg_6.png")}
          alt=""
          className="bg6"
        />
        <img
          src={require("assets/images/home/bg_5.png")}
          alt=""
          className="bg5"
        />
      </Block>
      <Block className="bg2">
        <InnerBlock>
          <Row justify="center" gap="18px">
            <LazAI />
            <TYPE.main fontSize={48} fontWeight={600} mobileFz={24}>
              SHOWCASES
            </TYPE.main>
          </Row>
          <Row justify="center">
            <TYPE.main
              fontSize={26}
              mobileFz={18}
              textAlign="center"
              marginTop={40}
              wordBreak
            >
              Our events reveal how LazAI reshapes industries,
              <br /> fostering innovation and creative empowerment.
            </TYPE.main>
          </Row>
          <ShowsWrapper>
            <ShowWrapper>
              <ShowItem>
                <img src={require("assets/images/home/show/1.png")} alt="" />
                <div className="button">Doge NFT Palooza</div>
              </ShowItem>
              <ShowItem>
                <img src={require("assets/images/home/show/2.png")} alt="" />
                <div className="button">Meme Playground</div>
              </ShowItem>
            </ShowWrapper>
            <ShowWrapper>
              <ShowItem>
                <img src={require("assets/images/home/show/3.png")} alt="" />
                <div className="button">Cosmic Ocean</div>
              </ShowItem>
            </ShowWrapper>
          </ShowsWrapper>
        </InnerBlock>

        <img
          src={require("assets/images/home/bg_8.png")}
          alt=""
          className="bg8"
        />
      </Block>
    </Wrapper>
  );
}
