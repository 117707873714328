export const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";

export enum ChainId {
  testnet = 599,
  mainnet = 1088,
}

export const DefaultChainId = ChainId.testnet;

export const NETWORK_CONFIG = {
  [ChainId.testnet]: {
    name: "Metis(Goerli)",
    scanLable: "Metis(Goerli)-explore",
    explore: "https://goerli.explorer.metisdevops.link/",
  },
  [ChainId.mainnet]: {
    name: "Andromeda",
    scanLable: "Andromeda-explore",
    explore: "https://andromeda-explorer.metis.io/",
  },
};

export const SWTICH_NETWORK_CONFIG = {
  [ChainId.testnet]: {
    chainId: `0x${ChainId.testnet.toString(16)}`,
    chainName: "Metis Goerli",
    nativeCurrency: {
      name: "Metis Token",
      symbol: "METIS",
      decimals: 18,
    },
    rpcUrls: [
      "https://goerli.gateway.metisdevops.link",
      // `https://stardust.metis.io/?owner=${ChainId.layer2Testnet}`,
    ],
    blockExplorerUrls: ["https://goerli.explorer.metisdevops.link"],
  },
  [ChainId.mainnet]: {
    chainId: `0x${ChainId.mainnet.toString(16)}`,
    chainName: "Andromeda",
    nativeCurrency: {
      name: "Metis Token",
      symbol: "METIS",
      decimals: 18,
    },
    rpcUrls: [`https://andromeda.metis.io/?owner=${ChainId.mainnet}`],
    blockExplorerUrls: ["https://andromeda-explorer.metis.io"],
  },
};

export const RPC = {
  [ChainId.mainnet]: "https://andromeda-rpc.metis.io",
  [ChainId.testnet]: "https://goerli.gateway.metisdevops.link",
};

export type Token = {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
};
