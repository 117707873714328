import React, { useMemo } from "react";
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from "styled-components";
import { Text, TextProps } from "rebass";
import { darken } from "polished";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import TooltipInfo from "components/TooltipInfo";

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1200,
  upToMoreLarge: 1440,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export function color(isDark: boolean) {
  return {
    bg01: isDark ? "#10101C" : "#fff",
    bg02: isDark ? "#313144" : "#f1f4f9",
    bg03: isDark ? "#10101C" : "#fff",
    bg04: isDark ? "#131324" : "#f9f9f9",
    bg05: isDark ? "#1C1C33" : "#fff",
    t01: isDark ? "#fff" : "#000",
    t02: "#9d9d9d",
    t03: "#07DDD0",
    t04: isDark ? "#07DDD0" : "#000",
  };
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    isDark: darkMode,
    mediaWidth: mediaWidthTemplates,
    c: color(darkMode),
  };
}

export const ThemedGlobalStyle = createGlobalStyle`
.MuiTooltip-tooltip {
    padding: 12px;
    background: rgba(0,0,0,0.7);
    .MuiTooltip-arrow  {
        color: rgba(0,0,0,0.7);
    }
}
* {
    box-sizing: border-box;
font-family: 'Poppins',' PingFang SC' !important;
    ::-webkit-scrollbar {
        width: 8px;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 8px;
    }
  }
`;

interface TypeProps extends TextProps {
  ellipsis?: boolean;
  wordBreak?: boolean;
  mobileFz?: number;
  shrink?: boolean;
}

const TextWrapper = styled(Text)<TypeProps>`
  color: ${({ color, theme }) => (theme.c as any)[color as string]};
  width: ${({ ellipsis, wordBreak }) =>
    ellipsis || wordBreak ? "100%" : "unset"};
  overflow: ${({ ellipsis }) => (ellipsis ? "hidden" : "unset")};
  text-overflow: ${({ ellipsis }) => (ellipsis ? "ellipsis" : "unset")};
  white-space: ${({ ellipsis }) => (ellipsis ? "nowrap" : "unset")};
  word-break: ${({ wordBreak }) => (wordBreak ? "break-all" : "unset")};
  flex-shrink: ${({ shrink }) => (shrink ? 1 : 0)};
`;

export const TYPE = {
  main(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        color="t01"
        {...props}
        fontSize={`${(Number(fontSize) || 16) / 16}rem`}
      />
    );
  },
  desc(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        color="t02"
        {...props}
        fontSize={`${(Number(fontSize) || 16) / 16}rem`}
      />
    );
  },
};

interface LinkProps {
  href: string;
  children: React.ReactNode;
  inner?: boolean;
  fontSize?: string;
  mbFrontSize?: string;
  color?: string;
  disableText?: string;
}

export const Link = (linkProps: LinkProps) => {
  const { href, children, inner, disableText } = linkProps;
  const Link = styled.a<LinkProps>`
    font-size: ${({ fontSize }) => fontSize || "16px"};
    font-weight: 400;
    color: ${({ color }) => color || "#040817"};
    line-height: 25px;
    text-decoration: underline;
    ${({ theme, mbFrontSize }) => theme.mediaWidth.upToSmall`
          fontSize: ${mbFrontSize || "16px"} 
        `};
  `;

  const InnerLink = styled(NavLink)<LinkProps>`
    font-size: ${({ fontSize }) => fontSize || "16px"};
    font-weight: 400;
    color: ${({ color }) => color || "#040817"};
    line-height: 25px;
    text-decoration: underline;
    ${({ theme, mbFrontSize }) => theme.mediaWidth.upToSmall`
          fontSize: ${mbFrontSize || "16px"} 
        `};
  `;
  return disableText ? (
    <TooltipInfo text={disableText}>
      <Link {...linkProps} href="#" target="_blank">
        {children}
      </Link>
    </TooltipInfo>
  ) : inner ? (
    <InnerLink to={href} {...linkProps}>
      {children}
    </InnerLink>
  ) : (
    <Link {...linkProps} target="_blank">
      {children}
    </Link>
  );
};
