import { Token } from 'constant';
import { makeAutoObservable } from 'mobx';

interface ConfimModal {
    isOpen: boolean;
    attemptingTxn: boolean;
    hash: string;
    pendingText: string;
    errorMessage?: string;
    addTokenToMetamask?: Token;
}

export const InitConfirmModalConfig = {
    isOpen: false,
    attemptingTxn: false,
    hash: '',
    pendingText: '',
    errorMessage: undefined,
    addTokenToMetamask: undefined,
};

export class Application {
    openConnectWallet: boolean = false;
    confirmModal: ConfimModal = InitConfirmModalConfig;
    hideMainnet: boolean = true;
    constructor() {
        makeAutoObservable<this>(this);
    }

    toggleConnectWalletModal = () => {
        this.openConnectWallet = !this.openConnectWallet;
    };

    setConfimModal = (data: ConfimModal) => {
        this.confirmModal = data;
    };
    setCloseConfirModalState = () => {
        this.confirmModal = InitConfirmModalConfig;
    };
}

export default new Application();
