import { ChainId, SWTICH_NETWORK_CONFIG } from '../constant';
import user from 'stores/user';
import AlertFunc from 'utils/components/alert';

function handleError(e: any) {
    console.error(e);
    let message;
    if ((e as any).code === 4001) {
        return;
    }
    if ((e as any).code === -32002) {
        message = 'Please approve the chain change request in your wallet';
    }

    AlertFunc({
        type: 'error',
        duration: 10000,
        text:
            message ||
            (e as any).message ||
            'Something wrong, please try again later',
    });
}

export default function useWeb3SwitchNetwork() {
    const { chainId, address, web3 } = user;
    function handleChangeNetwork(cid: ChainId) {
        return new Promise((resolve) => {
            const params = SWTICH_NETWORK_CONFIG[cid];

            if (
                cid === chainId ||
                cid === ChainId.mainnet ||
                cid === ChainId.testnet
            ) {
                if (cid === ChainId.mainnet || cid === ChainId.testnet) {
                    // Modal({
                    //     type: 'error',
                    //     title: 'Sorry',
                    //     text: `Due to security policy restrictions, You need to manually switch the network to ${
                    //         cid === ChainId.mainnet ? 'Ethereum' : 'Rinkeby'
                    //     } in metamask`,
                    //     buttonText: 'OK',
                    // });

                    (window as any).ethereum
                        .request({
                            method: 'wallet_switchEthereumChain',
                            params: [
                                {
                                    chainId: `0x${cid.toString(16)}`,
                                },
                            ],
                        })
                        .then((res: any) => {
                            console.log(res);
                        })
                        .catch((e: any) => {
                            handleError(e);
                        });
                }
                resolve(false);
                return;
            }
            if (
                params &&
                (window as any).web3 &&
                (window as any).web3.currentProvider
            ) {
                (window as any).web3.currentProvider
                    .send('wallet_addEthereumChain', [params, address])
                    .then(async (res: any) => {
                        if (web3) {
                            const currentChainId = await web3.eth.chainId();
                            if (Number(currentChainId) === cid) {
                                resolve(true);
                                return;
                            }
                        }

                        resolve(false);
                    })
                    .catch((e: any) => {
                        handleError(e);
                        resolve(false);
                    });
            } else {
                resolve(false);
            }
        });
    }
    return {
        handleChangeNetwork,
    };
}
