import { ChainId } from 'constant';

export default {
    [ChainId.mainnet]: {
        graph: '',
        exchangeSubgraph:
            'https://andromeda.thegraph.metis.io/subgraphs/name/netswap/exchange',
    },
    [ChainId.testnet]: {
        graph: '',
        exchangeSubgraph:
            'https://goerli.thegraph.metis.io/subgraphs/name/netswap/exchange',
    },
};
