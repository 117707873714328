import React from 'react';
import Modal from 'components/Modal';
import Loading from './index';
import Row from '../Row';
import styled from 'styled-components';
import { TYPE } from 'theme';

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    flex-direction: column;
    padding: 20px;
`;

interface Props {
    loading: boolean;
}

export default function TimeLoading({ loading }: Props) {
    if (!loading) return null;
    return (
        <Modal
            isOpen
            onClose={() => {}}
            hideClose
            disableBackgroundClick
            // customContentClass={style.timeModalContent}
        >
            <LoadingWrapper>
                <Loading size={60} />
                <Row justify="center" marginTop="16px">
                    <TYPE.main>Connecting...</TYPE.main>
                </Row>
            </LoadingWrapper>
        </Modal>
    );
}
