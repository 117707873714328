/* eslint-disable react/no-deprecated */
import React from 'react';
import { AlertColor } from '@mui/material/Alert';
import ReactDOM from 'react-dom';
import Alert from 'components/Alert';

interface Props {
    type?: AlertColor;
    title?: string;
    text: string | React.ReactNode;
    duration?: number;
    key?: string;
    anchorOrigin?: {
        vertical: 'top' | 'bottom';
        horizontal: 'center' | 'left' | 'right';
    };
}

const AlertFunc = ({
    type,
    title,
    text,
    key,
    anchorOrigin,
    duration,
}: Props) => {
    let targetRef: any;
    // const propsRef: React.MutableRefObject<Props> = useRef(props);

    const handleDestroy = () => {
        if (!targetRef) {
            return;
        }

        const unmountResult = ReactDOM.unmountComponentAtNode(targetRef);
        if (unmountResult && targetRef && targetRef.parentNode) {
            targetRef.parentNode.removeChild(targetRef);
        }

        targetRef = undefined;
    };

    const render = function () {
        if (!targetRef) {
            targetRef = document.createElement('div');
            document.body.appendChild(targetRef);
        }

        const component = React.createElement(
            Alert as any,
            {
                isOpen: true,
                type,
                title,
                text,
                key,
                anchorOrigin,
                duration,
                onClose: handleDestroy,
            },
            null,
        );

        ReactDOM.render(component, targetRef);
    };

    render();
};

export default AlertFunc;
