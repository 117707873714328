import { Token } from 'constant';
import { useMemo } from 'react';
import { getTokenImg } from 'utils';
import { useGetContractByChainId } from './useContract';
import ERC20_ABI from 'contracts/erc20.json';
import user from 'stores/user';

export interface TokenParams {
    address: string;
    symbol: string;
    decimals: number;
    logoURI: string;
}

export function useAddToken(token?: Token): [boolean, () => Promise<void>] {
    const getContractByChainId = useGetContractByChainId();
    const canAdd = useMemo(() => {
        return !!user.address;
    }, [user]);

    async function addToken() {
        if (!token) return;
        const img = (token as any)?.img || getTokenImg(token);
        const contract = getContractByChainId(token.address, ERC20_ABI);
        let decimals = 0;
        if (contract) {
            decimals = await contract.decimals();
        }
        console.log(decimals);

        if (token.address && decimals) {
            const params: any = {
                type: 'ERC20',
                options: {
                    address: token.address,
                    symbol: token.symbol,
                    decimals: decimals,
                    image: img,
                },
            };
            user.web3.currentProvider
                .request({
                    method: 'wallet_watchAsset',
                    params,
                })
                .then((success: any) => {
                    console.log(success);
                    if (success) {
                        console.log(`Adding ${token.symbol}`);
                    } else {
                        console.error('Add token faild', success);
                    }
                })
                .catch(console.error);
        } else {
            console.error(`token ${token.symbol} address or decimals error`);
        }
    }
    return [canAdd, addToken];
}
